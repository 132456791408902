.appvide {
  display: flex;
  align-items: center;
}

.appcanvas {
  position: absolute;
}

.myapp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

/* overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.mic {
  display: flex;
  /* height: 20vh; */
  /* margin-bottom: 5vh; */
  justify-content: center;
  position: fixed;
  top: 70%;
  right: 50%;
  transform: translate(50%, 50%);
}

.text {
  height: 30vh;
}

/* video */
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.video-background {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  width: 100%;
  position: absolute;
  bottom: 0px;
  text-align: center;
  color: #fff;
}
